import { useRef } from 'react';
import { ChevronDownIc } from '@dsch/dd-icons';
import * as Styled from 'components/ToolkitV2/Accordion/Accordion.styled';
import type { AccordionProps } from 'components/ToolkitV2/Accordion/Accordion.typed';
import withDefaultProps from 'components/ToolkitV2/hoc/withDefaultProps';
import withStringOrComponent from 'components/ToolkitV2/hoc/withStringOrComponent';
import { useTheme } from 'styled-components';
import { Title } from 'components/Toolkit/InfoTitle/Title/Title';

const Accordion = (props: AccordionProps) => {
  const {
    isOpen,
    onChange,
    header,
    content,
    showDivider = true,
    dataTracking,
  } = props;
  const heading = withStringOrComponent(header, Accordion.Heading);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const onKeyDown = (event: React.KeyboardEvent) => {
    const key = event.code.toLowerCase();
    const isFocussed = document.activeElement === headerRef.current;

    if (isFocussed && (key === 'enter' || key === 'space')) {
      event.preventDefault();
      onChange();
    }
  };

  return (
    <Styled.Accordion showDivider={showDivider}>
      <Styled.Header
        ref={headerRef}
        tabIndex={0}
        role="button"
        aria-expanded={isOpen}
        onClick={onChange}
        onKeyDown={onKeyDown}
        data-tracking={dataTracking}
        data-testid={'accordion-header'}
      >
        <div>{heading}</div>

        <Styled.ChevronIconWrapper isOpen={isOpen}>
          <ChevronDownIc
            width="20"
            height="20"
            color={theme.colors.GREY_DARKER}
            data-testid={'accordion-chevron'}
          />
        </Styled.ChevronIconWrapper>
      </Styled.Header>
      <Styled.Body data-testid={'accordion-body'} isOpen={isOpen}>
        {content}
      </Styled.Body>
    </Styled.Accordion>
  );
};

Accordion.Heading = withDefaultProps(Title, {
  flip: false,
  shouldTruncate: false,
  variant: 'DEFAULT',
});

export { Accordion };
