import { useState, useEffect, createRef } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import Modal, { Props } from 'react-modal';
import { CloseOutlineIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';

import { Button } from 'components/Toolkit/Button/Button';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';

/** for 3 screen sizes: [sm, md, lg] */
type HorizontalPosProps = [
  'left' | 'center' | 'right',
  'left' | 'center' | 'right',
  'left' | 'center' | 'right',
];

export interface TooltipProps extends Omit<Props, 'isOpen' | 'onRequestClose'> {
  onHandleTooltipCallback?: () => void;
  hasCloseOption?: boolean;
  hideCloseOptionOnDesktop?: boolean;
  title?: string;
  titleIcon?: ReactNode;
  subtitle?: string | ReactNode;
  hasButton?: boolean;
  buttonType?: 'BUTTON' | 'LINK_BUTTON';
  buttonText?: string;
  tooltipPosition?: 'above' | 'below';
  arrowPosition?: HorizontalPosProps;
  children?: ReactNode;
  hideArrow?: boolean;
  displayInline?: boolean;
  childWidth?: number;
  className?: string;
  includeHover?: boolean;
  iconSrc?: string;
}

Modal.setAppElement('#__next');

interface ISModal {
  tooltipPosition: 'above' | 'below';
  arrowPosition: HorizontalPosProps;
  coordinates?: ClientRect;
  childWidth?: number;
}
const SModal = styled.div<ISModal>`
  z-index: 9950;
  outline: none;
  position: absolute;
  top: ${({ tooltipPosition }) =>
    tooltipPosition === 'above' ? 'auto' : 'calc(100% + 12px)'};
  bottom: ${({ tooltipPosition }) =>
    tooltipPosition === 'above' ? 'calc(100% + 12px)' : 'auto'};

  ${({ arrowPosition, childWidth }) => {
    if (arrowPosition[0] === 'left') {
      // this will center the arrow on the child
      return `left: ${
        childWidth ? `${-52 + childWidth}px` : '0'
      }; right: auto; transform: translateX(0);`;
    }
    if (arrowPosition[0] === 'center') {
      return 'left: 50%; right: auto; transform: translateX(-50%);';
    }
    if (arrowPosition[0] === 'right') {
      return 'left: auto; right: 0; transform: translateX(0);';
    }
  }}

  ${media.medium} {
    ${({ arrowPosition, childWidth }) => {
      if (arrowPosition[1] === 'left') {
        return `left: ${
          childWidth ? `${-52 + childWidth}px` : '0'
        }; right: auto; transform: translateX(0);`;
      }
      if (arrowPosition[1] === 'center') {
        return 'left: 50%; right: auto; transform: translateX(-50%);';
      }
      if (arrowPosition[1] === 'right') {
        return 'left: auto; right: 0; transform: translateX(0);';
      }
    }}
  }

  ${media.large} {
    ${({ arrowPosition, childWidth }) => {
      if (arrowPosition[2] === 'left') {
        return `left: ${
          childWidth ? `${-52 + childWidth}px` : '0'
        }; right: auto; transform: translateX(0);`;
      }
      if (arrowPosition[2] === 'center') {
        return 'left: 50%; right: auto; transform: translateX(-50%);';
      }
      if (arrowPosition[2] === 'right') {
        return 'left: auto; right: 0; transform: translateX(0);';
      }
    }}
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.M16};
  padding-top: ${({ theme }) => theme.spacing.M16};
  background: ${({ theme }) => theme.colors.WHITE};
  width: 284px;
  border-radius: 4px;
  -webkit-overflow-scrolling: touch;
`;

const Close = styled.span<{ hideCloseOptionOnDesktop: boolean }>`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: 0;
  cursor: pointer;

  ${media.large} {
    ${({ hideCloseOptionOnDesktop }) =>
      hideCloseOptionOnDesktop ? 'display: none' : undefined};
  }
`;

const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

// left -> 0px, center -> 95px (33%), right: 190px (66%)
interface IArrow {
  tooltipPosition: 'above' | 'below';
  arrowPosition: HorizontalPosProps;
}
const Arrow = styled.div<IArrow>`
  overflow: hidden;
  position: absolute;
  height: 12px;
  width: 95px;
  left: ${({ arrowPosition }) =>
    arrowPosition[0] === 'left'
      ? '0'
      : arrowPosition[0] === 'center'
      ? '33%'
      : '66%'};
  ${({ tooltipPosition }) =>
    tooltipPosition === 'above'
      ? 'bottom: -12px;'
      : 'top: -12px; transform: rotate(180deg);'}

  &:after {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    top: -13px;
    left: 35px;
    background: ${({ theme }) => theme.colors.WHITE};
    transform: rotate(45deg);
  }

  ${media.medium} {
    left: ${({ arrowPosition }) =>
      arrowPosition[1] === 'left'
        ? '0'
        : arrowPosition[1] === 'center'
        ? '33%'
        : '66%'};
  }

  ${media.large} {
    left: ${({ arrowPosition }) =>
      arrowPosition[2] === 'left'
        ? '0'
        : arrowPosition[2] === 'center'
        ? '33%'
        : '66%'};
  }
`;

const TopContainer = styled.div<{ hasCloseOption: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${({ theme, hasCloseOption }) =>
    hasCloseOption ? theme.spacing.S4 : '0'};
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.fontSize.B14};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
  display: inline;
`;

const TitleIcon = styled.span`
  display: inline;
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const Subtitle = styled.p<{ hasButton: boolean; title?: string | ReactNode }>`
  ${({ theme }) => theme.fontSize.B14};
  font-weight: ${({ title, theme }) =>
    title ? theme.fontWeight.regular : theme.fontWeight.bold};
  margin-bottom: ${({ hasButton, theme }) =>
    hasButton ? theme.spacing.M16 : '0'};
  color: ${({ theme, title }) =>
    title ? theme.colors.GREY_DARK : theme.colors.GREY_DARKER};
  ${({ title }) => (title ? `grid-column: 1 / span 2` : `order: -1`)};
  text-transform: none;
`;

const SLinkButton = styled(LinkButton)`
  align-self: flex-end;
`;

interface IContainer {
  displayInline: boolean;
}
const Container = styled.div<IContainer>`
  position: relative;
  display: ${({ displayInline }) => (displayInline ? 'inline' : 'block')};
  width: 100%;
`;

const CTAContainer = styled.div<IContainer>`
  display: ${({ displayInline }) => (displayInline ? 'inline' : 'block')};
  width: 100%;
`;

const Simg = styled.img`
  margin-right: ${({ theme }) => theme.spacing.M16};
  height: 40px;
  width: 40px;
`;

function Tooltip({
  hasCloseOption = true,
  hideCloseOptionOnDesktop = false,
  title,
  titleIcon,
  subtitle,
  hasButton = false,
  buttonType = 'BUTTON',
  buttonText,
  onHandleTooltipCallback,
  tooltipPosition = 'below',
  arrowPosition = ['center', 'center', 'center'],
  children,
  hideArrow = false,
  displayInline = false,
  childWidth = 0,
  className,
  includeHover,
  iconSrc,
}: TooltipProps) {
  let modalRef = createRef<HTMLDivElement>();
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const handleMouseOver = () => setIsTooltipOpen(true);
  const handleMouseOut = () => setIsTooltipOpen(false);

  useEffect(() => {
    if (includeHover) {
      const node = modalRef.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    }
  }, [modalRef.current, includeHover]);

  useEffect(() => {
    if (!includeHover) {
      if (isFirst) {
        if (isTooltipOpen) {
          document.addEventListener('mousedown', handleClickWithTooltipOpen);
          document.addEventListener('keydown', handleClickWithTooltipOpen);
        } else {
          document.removeEventListener('mousedown', handleClickWithTooltipOpen);
          document.removeEventListener('keydown', handleClickWithTooltipOpen);
        }

        return () => {
          document.removeEventListener('mousedown', handleClickWithTooltipOpen);
          document.removeEventListener('keydown', handleClickWithTooltipOpen);
        };
      } else {
        setIsFirst(true);
      }
    }
  }, [isTooltipOpen, includeHover]);

  function handleClick() {
    setIsTooltipOpen(true);
  }

  function handleClickWithTooltipOpen(event: MouseEvent | KeyboardEvent) {
    let element = event.target as HTMLElement;
    if (element.id === 'tooltip-action-btn') {
      return;
    }
    if (modalRef?.current && modalRef.current.contains(event.target as Node)) {
      return;
    }
    setIsTooltipOpen(false);
  }

  function handleTooltipCallback() {
    if (onHandleTooltipCallback) {
      setIsTooltipOpen(false);
      onHandleTooltipCallback();
    }
  }

  const tooltip = (
    <SModal
      arrowPosition={arrowPosition}
      tooltipPosition={tooltipPosition}
      childWidth={childWidth}
    >
      <ModalWrapper ref={!includeHover ? modalRef : null}>
        <IconAndTextContainer>
          {iconSrc ? (
            <Simg src={iconSrc} height="40" width="40" alt="" />
          ) : undefined}
          <TopContainer title={title} hasCloseOption={hasCloseOption}>
            {title && (
              <TitleWrapper>
                {titleIcon && <TitleIcon>{titleIcon}</TitleIcon>}
                <span>{title}</span>
              </TitleWrapper>
            )}
            {hasCloseOption && (
              <Close
                onClick={() => setIsTooltipOpen(false)}
                data-testid="tooltip-modal"
                hideCloseOptionOnDesktop={hideCloseOptionOnDesktop}
              >
                <CloseOutlineIc width="12" height="12" />
              </Close>
            )}
            {typeof subtitle === 'string' ? (
              <Subtitle
                title={title}
                hasButton={hasButton}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            ) : (
              <Subtitle title={title} hasButton={hasButton}>
                {subtitle}
              </Subtitle>
            )}
          </TopContainer>
        </IconAndTextContainer>
        {hasButton &&
          (buttonType === 'BUTTON' ? (
            <Button onClick={handleTooltipCallback} id="tooltip-action-btn">
              {buttonText}
            </Button>
          ) : (
            <SLinkButton
              onClick={handleTooltipCallback}
              id="tooltip-action-btn"
            >
              {buttonText}
            </SLinkButton>
          ))}

        {!hideArrow && (
          <Arrow
            arrowPosition={arrowPosition}
            tooltipPosition={tooltipPosition}
          />
        )}
      </ModalWrapper>
    </SModal>
  );

  return (
    <Container
      ref={includeHover ? modalRef : null}
      displayInline={displayInline}
      className={className}
    >
      {isTooltipOpen && tooltip}
      <CTAContainer displayInline={displayInline} onClick={handleClick}>
        {children}
      </CTAContainer>
    </Container>
  );
}

export { Tooltip };
